import React, { useState, useEffect } from "react";
import Header from "../components/Header/Header";
import yep from "../assets/yep.jpg";
import Footer from "../components/Footer/Footer";
import ApplicationTimeline from "../components/ApplicationTimeline/ApplicationTimeline";
import { API_URL } from "../core/constants";
import Toast from "../components/Toast/Toast";
import axios from "axios";
import { useParams, useNavigate } from "react-router-dom";

const DocumentUpload = () => {
  const [loading, setLoading] = useState(true);
  const [loanAmount, setLoanAmount] = useState(0);
  const [disabled, setDisabled] = useState(true);
  const [name, setName] = useState("");
  const [errored, setErrored] = useState(false);

  const [principalLoading, setPrincipalLoading] = useState(false);
  const [principalDone, setPrincipalDone] = useState(false);
  const [principalError, setPrincipalError] = useState(false);

  const [secondaryLoading, setSecondaryLoading] = useState(false);
  const [secondaryDone, setSecondaryDone] = useState(false);
  const [secondaryError, setSecondaryError] = useState(false);

  const [referralLoading, setReferralLoading] = useState(false);
  const [referralDone, setReferralDone] = useState(false);
  const [referralError, setReferralError] = useState(false);

  let navigate = useNavigate();
  let { id, hash } = useParams();

  if (!id || !hash) {
    navigate("/no-application", { replace: false });
  }

  useEffect(() => {
    const fetchApplication = () => {
      setLoading(true);
      axios
        .get(`${API_URL}/application/${id}/${hash}`)
        .then(function (response) {
          console.log("response", response);
          setLoanAmount(response.data.body.application.amount);
          setName(response.data.body.application.firstName);
          setLoading(false);
        })
        .catch(function (error) {
          Toast.popError(
            "Oh no, something has gone wrong. Please try again or contact us."
          );
          console.log(error);
          setLoading(false);
          setErrored(true);
        });
    };

    fetchApplication();
  }, [id, hash]);

  useEffect(() => {
    const checkProgress = () => {
      console.log("checkProgress", principalDone, secondaryDone);
      if (principalDone && secondaryDone) {
        setDisabled(false);
      }
    };

    checkProgress();
  }, [principalDone, secondaryDone]);

  const handleSubmit = async (e) => {
    setLoading(true);
    setDisabled(true);

    axios
      .post(`${API_URL}/application/${id}/${hash}/finish`)
      .then((response) => {
        console.log("Finished response", response);
        window.analytics.track(`apply.step3.docs.complete`, {
          id: id,
          hash: hash,
        });
        navigate(`/apply/${id}/${hash}/finished`, { replace: false });
      })
      .catch((err) => {
        Toast.popError(
          `Oh snap! Something has gone wrong, please try again. If this problem persists, please contact us.`
        );
        console.log("Error finishing application", err);
        setLoading(false);
        setDisabled(false);
        return false;
      });
  };

  const handlePrincipalIdentityDocumentChange = (e) => {
    setPrincipalLoading(true);
    setPrincipalError(false);

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("principal", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${API_URL}/documents/${id}/${hash}/principal`, formData, config)
      .then((response) => {
        console.log("Upload response", response);
        window.analytics.track(`apply.step3.docs.principal.complete`, {
          id: id,
          hash: hash,
        });
        setPrincipalLoading(false);
        setPrincipalDone(true);
      })
      .catch((err) => {
        console.log("Error uploading", err);
        setPrincipalLoading(false);
        setPrincipalError(true);
      });
  };

  const handleSecondaryIdentityDocumentChange = (e) => {
    setSecondaryLoading(true);
    setSecondaryError(false);

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("secondary", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${API_URL}/documents/${id}/${hash}/secondary`, formData, config)
      .then((response) => {
        console.log("Upload response", response);
        window.analytics.track(`apply.step3.docs.secondary.complete`, {
          id: id,
          hash: hash,
        });
        setSecondaryLoading(false);
        setSecondaryDone(true);
      })
      .catch((err) => {
        console.log("Error uploading", err);
        setSecondaryLoading(false);
        setSecondaryError(true);
      });
  };

  const handleReferralDocumentChange = (e) => {
    setReferralLoading(true);
    setReferralError(false);

    const file = e.target.files[0];
    const formData = new FormData();
    formData.append("referral", file);
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };

    axios
      .post(`${API_URL}/documents/${id}/${hash}/referral`, formData, config)
      .then((response) => {
        console.log("Upload response", response);
        window.analytics.track(`apply.step3.docs.referral.complete`, {
          id: id,
          hash: hash,
        });
        setReferralLoading(false);
        setReferralDone(true);
      })
      .catch((err) => {
        console.log("Error uploading", err);
        setReferralLoading(false);
        setReferralError(true);
      });
  };

  const renderError = () => {
    return (
      <section>
        <div className="p-8 bg-slate-100 rounded-lg">
          <div className="w-full">
            <span className="block text-gray-900 text-center text-base">
              We're sorry, something has gone wrong and we can't get that
              application. Please check the details and try again, or contact us
              if the problem persists.
            </span>
          </div>
        </div>
      </section>
    );
  };

  const renderUploadError = () => {
    return (
      <div className="bg-red-100 p-2 rounded border-2 border-red-300 border-dashed">
        <p class="text-base text-red-800">
          Oh no. We couldn't upload that. Please try again. Check your file type
          and size isn't above outside our requirements. If the problem
          continues, please contact us.
        </p>
      </div>
    );
  };

  const renderDone = () => {
    return (
      <div className="bg-green-100 p-2 rounded border-2 border-green-300 border-dashed justify-center flex">
        <p class="text-base text-green-800">🙌 Great! We've got that.</p>
      </div>
    );
  };

  const renderUploadLoading = () => {
    return (
      <div class="space-y-1 text-center">
        <div class="flex text-base text-gray-600 justify-center">
          <p class="text-base text-gray-500 animate-pulse">
            Just a moment, we're uploading that, for big files this may take a
            minute...
          </p>
          <span className="text-sm text-gray-500">
            Please don't refresh your browser
          </span>
        </div>
      </div>
    );
  };

  const uri = `https://argid.im/apply/${id}/${hash}`;

  const doCopy = (url) => {
    navigator.clipboard.writeText(url);

    Toast.popSuccess("Copied!");
  };

  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yep} className="min-w-full object-cover object-top" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-2xl font-medium text-gray-900">
            Apply for finance
          </h1>
          <span className="mb-4 leading-tight text-lg drop-shadow block">
            Let's find you the right help
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container pt-16 pb-8 px-8 sm:px-0 mx-auto flex flex-wrap">
          <p class="leading-relaxed text-base ml-8">
            Our finance application process couldn't be simpler, if you let us
            know what you need and we'll help find the right product for you
            (loan or grant). Before you begin, please have your necessary
            documents to prove your identity (like a driving licence, council
            tax bill or utility bill). If you have a referral from a known
            organisation, please upload that here.
          </p>
        </div>
      </section>

      <section class="text-gray-600 body-font mt-8">
        <div class="container mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className="hidden md:block md:w-1/3 pr-10 text-base">
              <ApplicationTimeline atStep={3} />
            </div>
            <div className="w-full md:w-2/3">
              {errored ? (
                renderError()
              ) : loading ? (
                <section>
                  <div className="p-8 bg-slate-100 rounded-lg">
                    <div className="w-full">
                      <span className="block text-gray-900 text-center text-2xl animate-pulse">
                        Nearly there...
                      </span>
                    </div>
                  </div>
                </section>
              ) : (
                <section>
                  <div className="mb-8 text-base">
                    <div className="bg-indigo-600 rounded">
                      <div className="max-w-7xl mx-auto py-3 px-3 sm:px-6 lg:px-8">
                        <div className="flex items-center justify-between flex-wrap">
                          <div className="w-0 flex-1 flex items-center">
                            <p className="ml-3 font-medium text-white truncate">
                              <span className="hidden lg:inline">
                                Last step {name}, for your application of
                                &pound;{loanAmount} financing.
                              </span>
                              <span className="lg:hidden">
                                Nearly there {name}.
                              </span>
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="p-8 bg-slate-100 rounded-lg">
                    <h3 class="block w-full text-2xl text-gray-900 font-medium title-font mb-4 leading-none">
                      Document upload
                    </h3>
                    <p className="block">
                      This is the last step. We just need to confirm your
                      identity. You need to upload a scan or photo of one of any
                      of the documents listed below for each section, except for
                      referral which is optional. For example, you could upload
                      a valid photo driving licence and a utility bill with your
                      address on.
                    </p>
                    <p className="block mt-4">
                      We appreciate you may not have all these documents to
                      hand, therefore you can return to this page at any time to
                      upload them. Either bookmark this address, or copy and
                      paste this URL: (
                      <span
                        className="text-purple-500 cursor-pointer"
                        alt="Click here to copy your URL"
                        onClick={doCopy}
                      >
                        {uri}
                      </span>
                      ) and return here when you are ready.
                    </p>
                    <div className="relative">
                      <div class="grid gap-6 mb-6 grid-cols-1 lg:grid-cols-3">
                        <div className="col-span-1 lg:col-span-3 my-4">
                          <label class="block text-base font-medium text-gray-700">
                            {" "}
                            Photo Identification{" "}
                          </label>
                          {principalDone ? (
                            renderDone()
                          ) : (
                            <>
                              {principalError ? renderUploadError() : null}
                              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md bg-gray-200">
                                {principalLoading ? (
                                  renderUploadLoading()
                                ) : (
                                  <div class="space-y-1 text-center">
                                    <svg
                                      class="mx-auto h-12 w-12 text-gray-400"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 48 48"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div class="flex text-base text-gray-600 justify-center">
                                      <label
                                        for="principal-file-upload"
                                        class="relative cursor-pointer px-2 bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                      >
                                        <span>
                                          Upload your photo identification
                                        </span>
                                        <input
                                          id="principal-file-upload"
                                          name="principal-file-upload"
                                          type="file"
                                          className="sr-only"
                                          accept=".png,.jpg,.jpeg,.pdf,.gif"
                                          onInput={
                                            handlePrincipalIdentityDocumentChange
                                          }
                                        />
                                      </label>
                                      {/* <p class="pl-1">or drag and drop</p> */}
                                    </div>
                                    <p class="text-xs text-gray-500">
                                      We accept a scan or photo of UK or
                                      European photo driving licences,
                                      Government-issued identity cards or a
                                      valid passport. PNG, JPG, GIF, PDF up to
                                      5MB.
                                    </p>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        <div className="col-span-1 lg:col-span-3 my-4">
                          <label class="block text-base font-medium text-gray-700">
                            {" "}
                            Address Confirmation{" "}
                          </label>
                          {secondaryDone ? (
                            renderDone()
                          ) : (
                            <>
                              {secondaryError ? renderUploadError() : null}
                              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md bg-gray-200">
                                {secondaryLoading ? (
                                  renderUploadLoading()
                                ) : (
                                  <div class="space-y-1 text-center">
                                    <svg
                                      class="mx-auto h-12 w-12 text-gray-400"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 48 48"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div class="flex text-base text-gray-600 justify-center">
                                      <label
                                        for="secondary-file-upload"
                                        class="relative cursor-pointer px-2 bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                      >
                                        <span>
                                          Upload your address confirmation
                                        </span>
                                        <input
                                          id="secondary-file-upload"
                                          name="secondary-file-upload"
                                          type="file"
                                          className="sr-only"
                                          accept=".png,.jpg,.jpeg,.pdf,.gif"
                                          onInput={
                                            handleSecondaryIdentityDocumentChange
                                          }
                                        />
                                      </label>
                                      {/* <p class="pl-1">or drag and drop</p> */}
                                    </div>
                                    <p class="text-xs text-gray-500">
                                      We accept a scan or photo of a bank
                                      statement, council tax bill, utility bill
                                      or government letter (such-as from the tax
                                      office) dated within the last 3-months in
                                      your name and to your address. PNG, JPG,
                                      GIF, PDF up to 5MB.
                                    </p>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>

                        <div className="col-span-1 lg:col-span-3">
                          <label class="block text-base font-medium text-gray-700">
                            {" "}
                            Optional Referral{" "}
                          </label>
                          {referralDone ? (
                            renderDone()
                          ) : (
                            <>
                              {referralError ? renderUploadError() : null}
                              <div className="mt-1 flex justify-center px-6 pt-5 pb-6 border-2 border-gray-300 border-dashed rounded-md bg-gray-200">
                                {referralLoading ? (
                                  renderUploadLoading()
                                ) : (
                                  <div class="space-y-1 text-center">
                                    <svg
                                      class="mx-auto h-12 w-12 text-gray-400"
                                      stroke="currentColor"
                                      fill="none"
                                      viewBox="0 0 48 48"
                                      aria-hidden="true"
                                    >
                                      <path
                                        d="M28 8H12a4 4 0 00-4 4v20m32-12v8m0 0v8a4 4 0 01-4 4H12a4 4 0 01-4-4v-4m32-4l-3.172-3.172a4 4 0 00-5.656 0L28 28M8 32l9.172-9.172a4 4 0 015.656 0L28 28m0 0l4 4m4-24h8m-4-4v8m-12 4h.02"
                                        stroke-width="2"
                                        stroke-linecap="round"
                                        stroke-linejoin="round"
                                      />
                                    </svg>
                                    <div class="flex text-base text-gray-600 justify-center">
                                      <label
                                        for="referral-file-upload"
                                        class="relative cursor-pointer px-2 bg-white rounded-md font-medium text-indigo-600 hover:text-indigo-500 focus-within:outline-none focus-within:ring-2 focus-within:ring-offset-2 focus-within:ring-indigo-500"
                                      >
                                        <span>
                                          Upload your optional referral
                                        </span>
                                        <input
                                          id="referral-file-upload"
                                          name="referral-file-upload"
                                          type="file"
                                          className="sr-only"
                                          accept=".png,.jpg,.jpeg,.pdf,.gif"
                                          onInput={handleReferralDocumentChange}
                                        />
                                      </label>
                                      {/* <p class="pl-1">or drag and drop</p> */}
                                    </div>
                                    <p class="text-xs text-gray-500">
                                      We accept a scan or photo of your letter
                                      of referral. PNG, JPG, GIF, PDF up to 5MB.
                                    </p>
                                  </div>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="w-1/3 pr-10 text-base"> </div>

                  <div className="p-8 bg-slate-100 rounded-lg mt-8">
                    <div className="flex justify-end">
                      <button
                        disabled={disabled ? "disabled" : false}
                        class="disabled:opacity-50 text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg"
                        onClick={() => handleSubmit()}
                      >
                        Finish
                      </button>
                    </div>
                  </div>
                </section>
              )}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default DocumentUpload;
