import Header from "../components/Header/Header";
import grants from "../assets/6.jpg";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";

const Grants = () => {
  let navigate = useNavigate();

  const applyHandler = () => {
    navigate("/apply", { replace: false });
  };

  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={grants} className="min-w-full object-cover" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-3xl font-medium text-white">
            Grants
          </h1>
          <span className="mb-4 leading-tight text-1xl drop-shadow block text-white">
            Get emergency help
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container px-5 py-24 mx-auto flex flex-wrap">
          <h2 class="sm:text-3xl text-1xl text-gray-900 font-medium title-font mb-2 md:w-2/5 leading-none">
            For food, utilities, clothing or other emergency expenses.
          </h2>
          <div class="md:w-3/5 md:pl-6">
            <p class="leading-relaxed text-base">
              Argid.im offers up-to &pound;50 as a one-off grant for food,
              utilities, clothing and other emergency expenses.
            </p>
            <p class="leading-relaxed text-base mt-8">
              To be eligible for our grants, you must:
            </p>
            <ul className="list-disc mt-8 ml-8 mb-8 text-base">
              <li>Be over 18</li>
              <li>Live permanently in the Isle of Man</li>
              <li>Provide details of your income and expenditure</li>
              <li>
                Have a referral from a Citizens Advice Bureau, Credit Union,
                Food Bank, School, Place of Worship or other Community Group.
              </li>
            </ul>

            <button
              class="flex mt-8 text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg"
              onClick={applyHandler}
            >
              Apply for a grant
            </button>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default Grants;
