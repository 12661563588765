import React, { useRef } from "react";
import ReactConfetti from "react-confetti";

const SizedConfetti = (props) => {
  const confettiRef = useRef();
  // console.log("confetti w x h", props.width, props.height);
  return (
    <ReactConfetti
      width={props.width}
      height={props.height}
      {...props}
      ref={confettiRef}
    />
  );
};

export default SizedConfetti;

// })
