import Header from "../components/Header/Header";

const ThankYou = () => {
  return (
    <>
      <Header />
      <span>Thank You</span>
    </>
  );
};

export default ThankYou;
