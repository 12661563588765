import Header from "../components/Header/Header";
import crying from "../assets/crying.png";
import { useNavigate } from "react-router-dom";
import Footer from "../components/Footer/Footer";

const FourOhFour = () => {
  let navigate = useNavigate();

  const homeHandler = () => {
    navigate("/", { replace: false });
  };

  const contactHandler = () => {
    navigate("/contact", { replace: false });
  };

  return (
    <>
      <Header />
      <section class="text-gray-600 body-font pt-8">
        <div class="container mx-auto flex px-5 py-24 items-center justify-center flex-col">
          <img
            class="w-64 mb-10 object-cover object-center rounded"
            alt="Oh no"
            src={crying}
          />
          <div class="text-center lg:w-2/3 w-full">
            <h1 class="title-font sm:text-4xl text-3xl mb-4 font-medium text-gray-900">
              Oh no, something's gone wrong!
            </h1>
            <p class="mb-8 leading-relaxed text-base">
              The page you were looking for appears to have moved, deleted or
              does not exist. You can go back to where you were or proceed to
              these pages:
            </p>
            <div class="flex justify-center">
              <button
                class="inline-flex text-white bg-indigo-500 border-0 py-2 px-6 focus:outline-none hover:bg-indigo-600 rounded text-lg"
                onClick={homeHandler}
              >
                ZeroPA Home
              </button>
              <button
                class="ml-4 inline-flex text-gray-700 bg-gray-100 border-0 py-2 px-6 focus:outline-none hover:bg-gray-200 rounded text-lg"
                onClick={contactHandler}
              >
                Contact
              </button>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default FourOhFour;
