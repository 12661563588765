import React from "react";
import bg from "../../assets/well_small.jpg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Wallpaper = (props) => {
  return (
    <section className="relative flex items-center justify-center w-full min-h-[960px] h-screen overflow-hidden bg-flat-grey">
      <img
        src={bg}
        className="absolute z-10 min-w-full min-h-[960px] max-w-none overflow-hidden ml-[650px] md:ml-[350px] lg:ml-0"
        alt=""
      />
      <div className="absolute z-20 pt-80 px-5 lg:px-2 max-w-xl">
        <h1 class="title-font lg:text-4xl text-3xl mb-4 font-medium drop-shadow-md text-center text-white leading-none">
          Imagine loans without interest. That's the world of{" "}
          <span className="text-purple-300">Argid.im</span>
        </h1>
        <span className="mb-8 leading-tight text-2xl text-center drop-shadow text-white block">
          Interest free lending, &pound;50-&pound;1,000 over 1 to 52 weeks
        </span>
        <div className="text-center">
          <FontAwesomeIcon
            icon="fa-chevron-down"
            className="text-white text-2xl animate-bounce"
            title="Find out more this way"
            alt="Find out more this way"
          />
        </div>
      </div>
    </section>
  );
};

export default Wallpaper;
