import React from "react";
import Header from "../components/Header/Header";
import yep from "../assets/yep.jpg";
import Footer from "../components/Footer/Footer";
import ApplicationTimeline from "../components/ApplicationTimeline/ApplicationTimeline";

const NoApplicationFound = () => {
  return (
    <>
      <Header />
      <section className="relative flex w-full md:h-256 h-160 overflow-hidden bg-cyan-400">
        <img src={yep} className="min-w-full object-cover object-top" alt="" />
        <div className="container absolute left-1/2 transform -translate-x-1/2 -translate-y-1/2 m-8 bottom-0">
          <h1 class="title-font sm:text-4xl text-2xl font-medium text-gray-900">
            Apply for finance
          </h1>
          <span className="mb-4 leading-tight text-lg drop-shadow block">
            Let's find you the right help
          </span>
        </div>
      </section>

      <section class="text-gray-600 body-font">
        <div class="container pt-16 pb-8 px-8 sm:px-0 mx-auto flex flex-wrap">
          <p class="leading-relaxed text-base ml-8">
            Our finance application process couldn't be simpler, if you let us
            know what you need and we'll help find the right product for you
            (loan or grant). Before you begin, please have your referral details
            and necessary documents to prove your identity (like a driving
            licence, council tax bill or utility bill). We may ask you some
            questions about your income and expenditure, but we only ask the
            minimum questions we need.
          </p>
        </div>
      </section>

      <section class="text-gray-600 body-font mt-8">
        <div class="container mx-auto flex flex-wrap">
          <div className="flex flex-wrap w-full">
            <div className="hidden md:block md:w-1/3 pr-10 text-base">
              <ApplicationTimeline atStep={3} />
            </div>
            <div className="w-full md:w-2/3">
              <section>
                <div className="p-8 bg-slate-100 rounded-lg">
                  <div className="w-full">
                    <span className="block text-gray-900 text-center text-base">
                      We're really sorry, but we can't find that application.
                      You can always create a new application, or if you feel
                      this is an error, please contact us.
                    </span>
                  </div>
                </div>
              </section>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </>
  );
};

export default NoApplicationFound;
