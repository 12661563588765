const goodField = `bg-gray-50 border border-gray-300 text-gray-900 text-base rounded-lg focus:ring-purple-500 focus:border-purple-500 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-purple-500 dark:focus:border-purple-500`;
const badField = `${goodField} border-red-500`;

const ZeropaInput = ({ id, label, placeholder, required = false, formik }) => {
  return (
    <>
      <label
        htmlFor={id}
        className="block mb-2 text-base font-medium text-gray-900 dark:text-gray-300"
      >
        {required ? `${label} *` : label}
      </label>
      <input
        type="text"
        id={id}
        name={id}
        className={
          formik.touched[id] && formik.errors[id] ? badField : goodField
        }
        placeholder={placeholder}
        onBlur={formik.handleBlur}
        onChange={formik.handleChange}
        value={formik.values[id]}
        required={required}
      />
      {formik.touched[id] && formik.errors[id] ? (
        <span className="text-sm text-red-400 pl-2">{formik.errors[id]}</span>
      ) : null}
    </>
  );
};

export default ZeropaInput;
