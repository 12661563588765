import control from "../../assets/control.jpg";
import ok from "../../assets/ok.jpg";

import PropTypes from "prop-types";

const PictureSlice = (props) => {
  const renderSlicePoint = () => {
    return (
      <>
        <img
          src={control}
          className="min-w-full min-h-full max-w-none overflow-hidden object-cover"
          alt=""
        />
      </>
    );
  };

  const renderSliceOk = () => {
    return (
      <>
        <img
          src={ok}
          className="min-w-full min-h-96 max-w-none overflow-hidden"
          alt=""
        />
      </>
    );
  };

  const renderButton = () => {
    return (
      <button
        class="flex mx-auto mt-8 mb-8 text-white bg-purple-500 border-0 py-2 px-8 focus:outline-none hover:bg-purple-600 rounded text-lg"
        onClick={() => props.buttonHandler()}
      >
        {props.buttonText}
      </button>
    );
  };

  const renderText = (title, subtitle) => {
    return (
      <div className="absolute z-20 bottom-0 px-5 lg:px-2 max-w-xl">
        <h1 class="inline-block mx-auto bg-white rounded leading-snug title-font text-[2.4rem] px-2 mb-4 font-medium drop-shadow text-center w-full">
          {title}
        </h1>
        <span className="inline-block mx-auto rounded bg-white mb-8 leading-tight text-1xl drop-shadow text-center w-full p-2">
          {subtitle}
        </span>
        {props.withButton ? renderButton() : null}
      </div>
    );
  };

  const renderBlock = (fn) => {
    if (!fn) return <> </>;
    return (
      <section className="relative flex items-center justify-center w-full min-h-min h-screen overflow-hidden">
        {fn()}
        {renderText(props.title, props.subtitle)}
      </section>
    );
  };

  if (!props.slice) return renderBlock(renderSlicePoint);

  switch (props.slice) {
    case "point":
      return renderBlock(renderSlicePoint);
    case "ok":
      return renderBlock(renderSliceOk);
    default:
      return renderBlock(renderSlicePoint);
  }
};

PictureSlice.propTypes = {
  title: PropTypes.string,
  subtitle: PropTypes.string,
  withButton: PropTypes.bool,
  buttonText: PropTypes.string,
  buttonHandler: PropTypes.func,
};

PictureSlice.defaultProps = {
  title: null,
  subtitle: null,
  withButton: false,
  buttonText: null,
  buttonHandler: () => {},
};

export default PictureSlice;
