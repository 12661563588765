import argid from "../../assets/logo/argid-black-220-no-strap.png";

const LogoDark = () => {
  return (
    <img
      src={argid}
      alt="Argid.im logo"
      title="Argid.im - Brought to you by The Interest Free Loans Company"
      className="argid-logo"
      style={{ margin: "0 auto" }}
    />
  );
};

export default LogoDark;
