import PropTypes from "prop-types";

const ApplicationTimeline = (props) => {
  const on = ["bg-purple-200", "text-purple-400", "text-purple-500"];
  const off = [`bg-gray-200`, "text-gray-900", "text-gray-500"];

  const isSelected = (atStep, activateAtStep, index) => {
    if (atStep === activateAtStep) {
      return on[index];
    } else {
      return off[index];
    }
  };

  const { atStep } = props;

  return (
    <>
      <div className="md:flex pl-8">
        <ol class="relative border-l border-gray-200 dark:border-gray-700">
          <li class="mb-10 ml-4">
            <div
              class={`absolute w-3 h-3 ${isSelected(
                atStep,
                1,
                0
              )} rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700`}
            ></div>
            <time
              className={`mb-1 text-sm font-normal leading-none ${isSelected(
                atStep,
                1,
                1
              )} dark:text-gray-500`}
            >
              Start here
            </time>
            <h3
              class={`text-lg font-semibold ${isSelected(
                atStep,
                1,
                2
              )} dark:text-white`}
            >
              Get started
            </h3>
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              How much do you need?
            </p>
          </li>
          <li class="mb-10 ml-4">
            <div
              class={`absolute w-3 h-3 ${isSelected(
                atStep,
                2,
                0
              )} rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700`}
            ></div>
            <time
              className={`mb-1 text-sm font-normal leading-none ${isSelected(
                atStep,
                2,
                1
              )} dark:text-gray-500`}
            >
              Next
            </time>
            <h3
              class={`text-lg font-semibold ${isSelected(
                atStep,
                2,
                2
              )} dark:text-white`}
            >
              Understanding your situation
            </h3>
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              We take some personal details and information about your income
              and expenditure.
            </p>
          </li>
          <li class="mb-10 ml-4">
            <div
              class={`absolute w-3 h-3 ${isSelected(
                atStep,
                3,
                0
              )} rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700`}
            ></div>
            <time
              className={`mb-1 text-sm font-normal leading-none ${isSelected(
                atStep,
                3,
                1
              )} dark:text-gray-500`}
            >
              Then
            </time>
            <h3
              class={`text-lg font-semibold ${isSelected(
                atStep,
                3,
                2
              )} dark:text-white`}
            >
              Documents
            </h3>
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              Have your identity documents to hand (driving licence, utility
              bill, passport etc.), so you can upload these.
            </p>
          </li>
          <li class="mb-10 ml-4">
            <div
              class={`absolute w-3 h-3 ${isSelected(
                atStep,
                4,
                0
              )} rounded-full mt-1.5 -left-1.5 border border-white dark:border-gray-900 dark:bg-gray-700`}
            ></div>
            <time
              className={`mb-1 text-sm font-normal leading-none ${isSelected(
                atStep,
                4,
                1
              )} dark:text-gray-500`}
            >
              Finally
            </time>
            <h3
              class={`text-lg font-semibold ${isSelected(
                atStep,
                4,
                2
              )} dark:text-white`}
            >
              Done
            </h3>
            <p class="text-sm font-normal text-gray-500 dark:text-gray-400">
              Thank you! You're all done and we'll been in touch within 5 days
              (often sooner) with details on your application.
            </p>
          </li>
        </ol>
      </div>
    </>
  );
};

ApplicationTimeline.propTypes = {
  atStep: PropTypes.number,
};

ApplicationTimeline.defaultProps = {
  atStep: 1,
};

export default ApplicationTimeline;
