import { toast } from "react-toastify";

import "react-toastify/dist/ReactToastify.css";
import "./toast.css";

const defaultOptions = {
  position: "top-right",
  autoClose: 5000,
  hideProgressBar: false,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: false,
};

const Toast = {
  pop: (text, options = defaultOptions) => {
    toast.info(text, options);
  },
  popWarning: (text, options = defaultOptions) => {
    toast.warning(text, options);
  },
  popError: (text, options = defaultOptions) => {
    toast.error(text, options);
  },
  popSuccess: (text, options = defaultOptions) => {
    toast.success(text, options);
  },
};

export default Toast;
