import * as yup from "yup";

const applicationSchema = yup.object({
  title: yup.string().required("Required"),
  firstName: yup
    .string()
    .min(2, "Must be at least 5 characters")
    .max(55, "Must be less than 55 characters")
    .required("First name is a required field please."),
  lastName: yup
    .string()
    .min(2, "Must be at least 5 characters")
    .max(55, "Must be less than 55 characters")
    .required("Last name is a required field please."),
  addressStreet: yup
    .string()
    .min(5, "Must be at least 5 characters")
    .max(64, "Must be less than 64 characters")
    .required("The first line of your address is required, please."),
  addressStreet2: yup.string().max(64, "Must be less than 64 characters"),
  addressCity: yup
    .string()
    .min(2, "Must be at least 5 characters")
    .max(20, "Must be less than 20 characters")
    .required("City is a required field, please"),
  addressCounty: yup.string().max(20, "Must be less than 20 characters"),
  addressPostcode: yup
    .string()
    .min(4, "This postcode looks a little short")
    .max(10, "This postcode looks a little long")
    .required("Postcode is a required field, please"),
  emailAddress: yup
    .string()
    .min(5, "This doesn't look quite right")
    .max(90, "This doesn't look quite right")
    .email("Must be a valid email address")
    .required("Your email address is required, please"),
  phoneNumber: yup
    .string()
    .min(7, "This doesn't look quite long enough")
    .max(22, "This looks too long")
    .required("Your phone number is required, please"),
  dobDay: yup
    .number()
    .required("Your date of birth (day) is a required field, please"),
  dobMonth: yup
    .number()
    .required("Your date of birth (month) is a required field, please"),
  dobYear: yup
    .number()
    .required("Your date of birth (year) is a required field, please"),
  referral: yup
    .string()
    .required(
      "Please select who referred you. This is a required field, please"
    ),
});

export default applicationSchema;
